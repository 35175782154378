<template>
    <div class="page" id="reorganize">
        <div>
            <div class="tabsCls bg-white">
                <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
                    <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                                 :name="item.value"></el-tab-pane>
                </el-tabs>
            </div>
            <el-form class="query-form" v-if="selectTab == 1" style="margin-top: 10px" size="small" ref="inputForm" :inline="true"
                     :model="inputForm" @keyup.enter.native="getDataList(1,1)"
                     label-width="100px">
                <el-form-item label="出库原因" prop="reason">
                    <el-select
                            v-model="inputForm.reason"
                            clearable
                            placeholder="请选择出库原因"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in this.$dictUtils.getDictList('outbound_reason')"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出库申请人" prop="applicant">
                    <el-select
                            style="width: 100%;"
                            v-model="inputForm.applicant"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="请选择出库申请人"
                            @focus="remoteMethod"
                            :remote-method="remoteMethod"
                            :loading="applicantLoading">
                        <el-option
                                v-for="item in applicantList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="逾期状态" prop="overdue">
                    <el-select
                            v-model="inputForm.overdue"
                            clearable
                            placeholder="请选择逾期状态"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in overdueList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="具体原因" prop="specificReasons">
                    <el-input v-model.trim="inputForm.specificReasons"
                              placeholder="请输入具体原因(限50字)"
                              maxlength="50"
                              clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="预入库时间" prop="outDate">
                    <el-date-picker
                            v-model="inputForm.outDate"
                            type="daterange"
                            clearable
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1,1)">查询
                    </el-button>
                    <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
                </el-form-item>
                <el-form-item class="f_r">
                    <el-button type="primary" size="small" icon='el-icon-plus'
                               v-show="hasPermissionButton(`collection:business:${typePage}:add`)"
                               @click="addData(0)">新增入库
                    </el-button>
                </el-form-item>
            </el-form>
            <el-form class="query-form" style="margin-top: 10px" v-if="selectTab != 1" size="small" ref="inputForm2" :inline="true" :model="inputForm2"
                     @keyup.enter.native="getDataList(1,1)"
                     label-width="100px">
                <el-form-item label="入库原因" prop="reason">
                    <el-select
                            v-model="inputForm2.reason"
                            clearable
                            placeholder="请选择入库原因"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in this.$dictUtils.getDictList('outbound_reason')"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--            <el-form-item label="入库申请人" prop="applicant">-->
                <!--                <el-select-->
                <!--                        style="width: 100%;"-->
                <!--                        v-model="inputForm2.applicant"-->
                <!--                        filterable-->
                <!--                        remote-->
                <!--                        reserve-keyword-->
                <!--                        placeholder="请选择入库申请人"-->
                <!--                        @focus="remoteMethod"-->
                <!--                        :remote-method="remoteMethod"-->
                <!--                        :loading="applicantLoading">-->
                <!--                    <el-option-->
                <!--                            v-for="item in applicantList"-->
                <!--                            :key="item.id"-->
                <!--                            :label="item.name"-->
                <!--                            :value="item.id">-->
                <!--                    </el-option>-->
                <!--                </el-select>-->
                <!--            </el-form-item>-->
                <el-form-item label="入库状态" prop="storeState">
                    <el-select
                            v-model="inputForm2.storeState"
                            clearable
                            placeholder="请选择入库状态"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in storeStateList"
                                :key="item.state"
                                :label="item.stateName"
                                :value="item.state"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申请状态" prop="applicationState">
                    <el-select
                            v-model="inputForm2.applicationState"
                            clearable
                            placeholder="请选择申请状态"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in applicationStateList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核状态" prop="reviewState">
                    <el-select
                            v-model="inputForm2.reviewState"
                            clearable
                            placeholder="请选择审核状态"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in reviewStateList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="预入库时间" prop="outDate">
                    <el-date-picker
                            v-model="inputForm2.outDate"
                            type="daterange"
                            clearable
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="具体原因" prop="specificReasons">
                    <el-input v-model.trim="inputForm2.specificReasons"
                              placeholder="请输入具体原因(限50字)"
                              maxlength="50"
                              clearable>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1,1)">查询
                    </el-button>
                    <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="bg-white" style="margin-top: 10px">
            <el-table
                    v-show="selectTab == 1"
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable1"
                    height="calc(100vh - 385px)"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column prop="reason" label="出库原因" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("outbound_reason", scope.row.reason, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="applicantName" label="出库申请人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="specificReasons" label="具体原因" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outBountTotal" label="已出库藏品种数" width="120px" show-overflow-tooltip></el-table-column>
                <el-table-column prop="inBountTotal" label="已入库藏品种数" width="120px" show-overflow-tooltip></el-table-column>
                <el-table-column prop="preDate" label="预入库时间" width="100px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.preDate | formatDate('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column prop="overdue" label="逾期状态" width="100px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.overdue == 0 ? '正常' : '已逾期' }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(1, scope.row,scope.$index,1)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(3, scope.row,scope.$index,1)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:warehous`)">
                            一键入库
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-table
                    v-show="selectTab != 1"
                    :data="dataList"
                    size="small"
                    v-loading="loading2"
                    ref="multipleTable2"
                    height="calc(100vh - 385px)"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column prop="reason" label="入库原因" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("outbound_reason", scope.row.reason, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="specificReasons" label="具体原因" show-overflow-tooltip></el-table-column>
                <el-table-column prop="collectionNum" label="藏品种数" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="storeState" label="入库状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getlistSelet(scope.row.storeState, storeStateList, 'state', 'stateName') }}
                    </template>
                </el-table-column>
                <el-table-column prop="applicationState" label="申请状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getlistSelet(scope.row.applicationState, applicationStateList, 'value', 'name') }}
                    </template>
                </el-table-column>
                <el-table-column prop="reviewState" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{
                        scope.row.reviewState == 0 ? '审核中' : scope.row.reviewState == 1 ? '审核驳回' : '审核通过'
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="submitDate" label="提交时间" width="100px">
                    <template slot-scope="scope">
                        {{ scope.row.submitDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="preDate" label="预入库时间" width="100px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.preDate | formatDate('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(1, scope.row,scope.$index,2)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(2,scope.row,scope.$index,2)"
                                   v-if="scope.row.reviewState == 1"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:resubmit`)">
                            重新提交
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="record(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:record`)">
                            审批记录
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <record ref="record"></record>
    </div>
</template>

<script>
import record from "@/views/modules/collection/accounts/module/record.vue";

export default {
    name: "application",
    components: {record},
    props: {
        typePage: {
            type: String,
            default: 'application',
        },
    },
    data() {
        return {
            selectTab: '1',
            tabsList: [
                {
                    name: '待入库列表',
                    value: '1',
                },
                {
                    name: '我申请的',
                    value: '2',
                },
            ],
            inputForm: {
                reason: "",
                applicant: '',

                outDate: [],
                preStartDate: '',
                preEndDate: '',
                overdue: '',
                specificReasons: '',
            },
            inputForm2: {
                reason: '',
                applicant: '',
                storeState: '',
                applicationState: '',
                reviewState: '',
                outDate: [],
                preStartDate: '',
                preEndDate: '',
                specificReasons: '',
            },
            applicationStateList: [
                {
                    name: '申请中',
                    value: '0',
                },
                {
                    name: '申请驳回',
                    value: '1',
                },
                {
                    name: '申请通过',
                    value: '2',
                },
                {
                    name: '已结束',
                    value: '3',
                },
            ],
            reviewStateList: [
                {
                    name: '审核中',
                    value: '0',
                },
                {
                    name: '审核驳回',
                    value: '1',
                },
                {
                    name: '审核通过',
                    value: '2',
                },
            ],
            applicantLoading: false,
            applicantList: [],
            storeStateList: [],
            overdueList: [
                {
                    value: '0',
                    label: '正常',
                },
                {
                    value: '1',
                    label: '已逾期',
                }
            ],
            loading: false,
            loading2: false,
            dataList: [],
            dataListSelect: [],
            pageNo: 1,
            pageNo2: 0,
            pageSize: 10,
            total: 0,
            searchRecord: {},
        }
    },

    mounted() {
        this.getAllState()
        this.handleClick()
    },

    methods: {
        getDataList(type, dividePage) {
            if (type == 1) {
                this.pageNo = 1
            }
            if (dividePage == 1) {
                this.$refs['multipleTable' + this.selectTab].clearSelection()
            }
            let searchData = JSON.parse(JSON.stringify(this.inputForm))
            if (this.selectTab != 1) {
                searchData = JSON.parse(JSON.stringify(this.inputForm2))
            }
            if (searchData.outDate && searchData.outDate.length == 2) {
                searchData.preStartDate = searchData.outDate[0]
                searchData.preEndDate = searchData.outDate[1]
            } else {
                searchData.preStartDate = ''
                searchData.preEndDate = ''
            }
            let fetchUrl = this.api.collection.listPendingInbount
            if (this.selectTab == 2) {
                fetchUrl = this.api.collection.listOrder
            }
            this.$delete(searchData, 'outDate')
            this.searchRecord = {
                ...searchData,
                current: this.pageNo2 ? this.pageNo2 : this.pageNo,
                orderType: 0,
                size: this.pageSize,
            }
            this.loading = true
            this.loading2 = true
            this.$axios(fetchUrl, this.searchRecord, 'post').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs['multipleTable' + this.selectTab].doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.pageNo > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
                    if (listSearch) {
                        this.pageNo = listSearch.pageNo
                        this.pageNo2 = 0
                        sessionStorage.removeItem('listSearch')
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
                this.loading2 = false
            })
        },

        handleClick(tab, event) {
            let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
            if (listSearch) {
                if (listSearch.selectTab == '1') {
                    this.inputForm = listSearch.inputForm
                }
                if (listSearch.selectTab == '2') {
                    this.inputForm2 = listSearch.inputForm
                }
                this.pageSize = listSearch.pageSize
                this.selectTab = listSearch.selectTab
                this.pageNo2 = listSearch.pageNo
            }
            if (this.pageNo2) {
                this.getDataList('', 1)
            } else {
                this.getDataList(1, 1)
            }
        },

        // 0新增 1详情 2修改 3一键入库
        addData(num, row, index, switchd) {
            if (num != 0) {
                let listSearch = {
                    inputForm: this.inputForm,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    selectTab: this.selectTab,
                }
                sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
            }
            if (num == 0) {
                this.$router.push({
                    path: '/collection/business/warehousing/wareDetail',
                    query: {butType: num},
                })
            } else {
                this.searchRecord.current = (this.pageNo - 1) * this.pageSize + index + 1
                this.searchRecord.size = 1
                sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
                this.$router.push({
                    path: '/collection/business/warehousing/wareDetail',
                    query: {butType: num, id: row.id, switch: switchd,}
                })
            }
        },

        //审核记录
        record(row) {
            this.$refs.record.init(row.id,'',15)
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', '');
        },

        //重置
        resetting() {
            if (this.selectTab == 1) {
                this.$refs.inputForm.resetFields();
            } else {
                this.$refs.inputForm2.resetFields();
            }
            this.getDataList(1, 1)
        },

        //列表展示
        getlistSelet(id, data, idName, name) {
            let seleRow = data.filter(item => {
                return item[idName] == id
            })
            if (seleRow.length != 0) {
                return seleRow[0][name]
            }
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        getAllState() {
            this.$axios(this.api.collection.listInBount, {}, 'get').then(data => {
                if (data.status) {
                    this.storeStateList = data.data
                }
            })
            this.$axios(this.api.collection.listLevelSelect).then(data => {
                if (data.status) {
                    this.leaveList = data.data
                }
            })
            this.$axios(this.api.collection.listSelect).then(data => {
                if (data.status) {
                    this.typeList = data.data
                }
            })
        },

        //边打边搜
        remoteMethod(query) {
            this.applicantLoading = true;
            this.$axios(this.api.collection.searchUserByLike, {username: query}, 'get').then(data => {
                if (data.status) {
                    this.applicantList = data.data
                    this.applicantLoading = false;
                }
            })
        },
    },
}
</script>

<style scoped>
.bg-white {
    overflow-y: auto !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
    position: static !important;
}

</style>
